import { useTransition } from "react";
import type { MenuProps } from "antd";
import { Dropdown } from "antd";
import { GlobalOutlined, CheckOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "./index.scss";

type Props = {};

interface LOCALE {
  key: string;
  label: string;
}

const locales: LOCALE[] = [
  { key: "en", label: "English" },
  { key: "ko", label: "한국어" },
  { key: "zh", label: "中文" },
];

export default function LocaleSwitcherSelect({}: Props) {
  const { t, i18n } = useTranslation();

  function onSelectChange({ key }: any) {
    i18n.changeLanguage(key);
  }

  const items: MenuProps["items"] = locales.map((cur) => ({
    key: cur.key,
    label: cur.label,
    icon: cur.key === i18n.language && <CheckOutlined />,
    onClick: onSelectChange,
  }));

  return (
    <>
      <Dropdown
        overlayClassName="locale-switcher-dropdown"
        menu={{ items, selectable: true, defaultSelectedKeys: [i18n.language] }}
      >
        <GlobalOutlined className="global opacity-80" />
      </Dropdown>
    </>
  );
}
