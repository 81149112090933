import { useEffect, useState } from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import "./style.scss";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

interface PlaylistItem {
  title: string;
  thumbnail: string;
  videoId: string;
}

let player: any = null;

const API_KEY = "AIzaSyBWkS08UkSB_dB1vyNn_9IF1AyZEaqA9-I";
const PLAYLIST_ID = "UUpQFTy3wTvCfcSJmSqlTMVg";

const DEFAULT_VIDEO_ID = "zBQzAM4c6uU";

export default ({ isOpen, onClose }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPlaying, setCurrentPlaying] =
    useState<string>(DEFAULT_VIDEO_ID);
  const [playlist, setPlaylist] = useState<PlaylistItem[]>([]);

  const { YT } = window;
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 300);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setOpen(isOpen);
      }, 300);
    }
  }, [isOpen]);

  const playerInitialize = async (event: any) => {
    setLoading(false);
    // event.target.playVideo();
  };

  // -1 – 未开始 0 – 已结束 1 – 正在播放 2 – 已暂停 3 – 正在缓冲 5 – 已插入视频
  const playerStateChange = (state: any) => {
    // console.log("state ==> ", state);
  };

  function onYouTubeIframeAPIReady() {
    setLoading(true);
    player = new YT.Player("iframe-video", {
      width: "100%",
      height: "100%",
      playerVars: {
        rel: 0,
        // list_type: "user_uploads",
        // list: PLAYLIST_ID,
      },
      videoId: DEFAULT_VIDEO_ID,
      events: {
        onReady: playerInitialize,
        onStateChange: playerStateChange,
      },
    });
  }

  useEffect(() => {
    if (YT?.ready && isOpen) {
      YT.ready(() => {
        onYouTubeIframeAPIReady();
      });
    }
  }, [YT, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const { gapi } = window;
      setCurrentPlaying(DEFAULT_VIDEO_ID);
      if (gapi && !playlist?.length) {
        function start() {
          // 2. Initialize the JavaScript client library.
          gapi.client.setApiKey(API_KEY);
          return gapi.client
            .load(
              "https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest"
            )
            .then(function () {
              return gapi.client.youtube.playlistItems
                .list({
                  part: "id,snippet,contentDetails",
                  playlistId: [PLAYLIST_ID],
                })
                .then(
                  function (response: any) {
                    // Handle the results here (response.result has the parsed body).
                    const videos = response.result?.items?.map((item: any) => ({
                      title: item.snippet?.title,
                      videoId: item.contentDetails?.videoId,
                      thumbnail: item.snippet?.thumbnails?.medium?.url,
                    }));
                    setPlaylist(videos || []);
                  },
                  function (err: any) {
                    console.error("Execute error", err);
                  }
                );
            });
        }
        gapi.load("client", start);
      }
    }
  }, [isOpen]);

  const handleThumbnailClick = (e: any, videoId: string) => {
    e.stopPropagation();

    if (currentPlaying === videoId) return;

    player.loadVideoById(videoId);
    setCurrentPlaying(videoId);
  };

  return (
    <>
      {isOpen && (
        <div
          className="lightbox-backdrop"
          onClick={handleClose}
          style={
            open
              ? {
                  opacity: 1,
                }
              : {
                  opacity: 0,
                }
          }
        >
          <div className="lightbox">
            <div className="lightbox-content">
              <div id="iframe-video"></div>
              {loading && (
                <div className="loading-container">
                  <LoadingOutlined style={{ fontSize: 60 }} />
                </div>
              )}
              {playlist?.length ? (
                <div className="playlist-container">
                  <div
                    className="playlist"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {playlist.map((listItem) => (
                      <div
                        key={listItem.videoId}
                        className={`img-wrapper ${
                          currentPlaying === listItem.videoId && "active"
                        }`}
                        onClick={(e) =>
                          handleThumbnailClick(e, listItem.videoId)
                        }
                      >
                        <img src={listItem.thumbnail} alt="" />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="video-block">
              <div
                id="iframe-video0"
                className="video-iframe"
                data-src="xcJtL7QggTI"
              ></div>
            </div>
            <div className="lightbox-close" onClick={handleClose}>
              <CloseOutlined />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
