/** @format */
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Buffer } from "buffer";
import AOS from "aos";
import "aos/dist/aos.css";

import "./i18n/conig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
window.Buffer = window.Buffer || Buffer;
AOS.init();
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
