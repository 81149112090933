/** @format */
import "./index.scss";
import { useTranslation, Trans } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  return (
    <div className="team-card ul-card">
      <div className="team-container" data-aos="fade-up">
        <ul className="title">
          <li>{t("team.title")}</li>
        </ul>
        <div className="desc">
          <Trans i18nKey="team.desc">
            A passionate team of industry experts in{" "}
            <span className="show-color linear">
              finance, blockchain and software development
            </span>
          </Trans>
        </div>
        <div className="sub-desc">{t("team.joinUs")}</div>
      </div>
    </div>
  );
};
