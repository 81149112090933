import en from "./jsons/en.json";
import ko from "./jsons/ko.json";
import zh from "./jsons/zh.json";

export const resources = {
  en: {
    translation: en,
  },
  ko: {
    translation: ko,
  },
  zh: {
    translation: zh,
  },
};
