/** @format */
import "./index.scss";
import { useEffect, useState, useRef } from "react";
import { Button, Carousel } from "antd";
import { useTranslation, Trans } from "react-i18next";
import LazyLoad from "react-lazyload";
import dayjs from "dayjs";

import { blogList } from "@/contants";

interface Enclosure {}

interface FeedItemObject {
  title: string;
  pubDate: string;
  link: string;
  guid?: string;
  author: string;
  thumbnail?: string;
  description?: string;
  content?: string;
  enclosure?: Enclosure;
  categories?: string[];
  imgUrl?: string;
}

export default () => {
  const [feeds, setFeeds] = useState<FeedItemObject[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation();

  const slider: any = useRef(null);

  const getMediumFeeds = async () => {
    try {
      const res = await fetch(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/@FILLiquid/feed"
      );
      const data = await res.json();
      setFeeds(data?.items || []);
    } finally {
    }
  };

  useEffect(() => {
    getMediumFeeds();
  }, []);

  const onChange = (currentSlide: number) => {
    setPageIndex(currentSlide);
  };

  const handlePrev = () => {
    slider?.current?.prev();
  };

  const handleNext = () => {
    slider?.current?.next();
  };

  return (
    <div className="blog-card fill-card ul-card" data-aos="fade-up" id="blog">
      <ul>
        <li>{t("blog.title")}</li>
      </ul>
      <div className="stories-container">
        <div className="title">
          <Trans i18nKey="blog.desc">
            <span className="opacity-9">Liquid staking and</span>
            <br />
            <span className="show-color linear">borrowing solutions</span>
          </Trans>
        </div>
        <div
          style={{
            display: "flex",
            gap: 40,
          }}
        >
          <div className="page-btn-wrapper">
            <Button
              className="page-btn"
              disabled={pageIndex === 0}
              onClick={handlePrev}
            >
              <span className="linear">{t("team.last-page")}</span>
            </Button>
          </div>
          <div className="page-btn-wrapper">
            <Button
              className="page-btn"
              disabled={pageIndex === 1}
              onClick={handleNext}
            >
              <span className="linear">{t("team.next-page")}</span>
            </Button>
          </div>
        </div>
      </div>
      <Carousel ref={slider} afterChange={onChange}>
        <div>
          <div className="blog-container">
            {feeds
              .concat(blogList)
              .slice(0, 6)
              .map((item) => (
                <div
                  className="container"
                  key={item.guid}
                  onClick={() => window.open(item.link)}
                >
                  <LazyLoad>
                    <div className="cover-container">
                      <div
                        className="cover"
                        style={{
                          backgroundImage: `url(${
                            item.description
                              ? item.description.match(
                                  /<img.*?src="([^">]*\/([^">]*?))".*?>/
                                )?.[1]
                              : item.imgUrl
                          })`,
                        }}
                      ></div>
                    </div>
                  </LazyLoad>
                  <div className="content">
                    <div className="title">{item.title}</div>
                    <div className="space-between">
                      <div className="name">{`By ${item.author}`} </div>
                      <div className="date">
                        {dayjs(item.pubDate).format("MMM DD,YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <div className="blog-container">
            {feeds
              .concat(blogList)
              .slice(6, 12)
              .map((item) => (
                <div
                  className="container"
                  key={item.guid}
                  onClick={() => window.open(item.link)}
                >
                  <LazyLoad>
                    <div className="cover-container">
                      <div
                        className="cover"
                        style={{
                          backgroundImage: `url(${
                            item.description
                              ? item.description.match(
                                  /<img.*?src="([^">]*\/([^">]*?))".*?>/
                                )?.[1]
                              : item.imgUrl
                          })`,
                        }}
                      ></div>
                    </div>
                  </LazyLoad>
                  <div className="content">
                    <div className="title">{item.title}</div>
                    <div className="space-between">
                      <div className="name">{`By ${item.author}`} </div>
                      <div className="date">
                        {dayjs(item.pubDate).format("MMM DD,YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Carousel>
    </div>
  );
};
