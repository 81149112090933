/** @format */
import "./style.scss";
import { knowList } from "@/contants";
import { Progress } from "antd";
import { useTranslation } from "react-i18next";

import logo from "@/assets/fill_logo_white.png";

import Header from "./Header";
import FirstScreen from "./FirstScreen";
import Holders from "./Holders";
import Video from "./Video";
import Services from "./Services";
import Team from "./Team";
import Partners from "./Partners";
import Blog from "./Blog";
import Footer from "./Footer";

const twoColors = { "0%": "#4ADFE7", "100%": "#4094E0" };

export default () => {
  const { t } = useTranslation();

  return (
    <div className="fill">
      <Header />

      <div className="fill-main">
        <FirstScreen />

        <Holders />

        <Video />

        <Services />

        <Team />

        <Blog />

        <Partners />

        <div className="know-card" data-aos="fade-up" id="docs">
          <div className="fill-card">
            {knowList.map((item, index) => (
              <div
                className="know-container"
                key={item.title}
                onClick={() => {
                  if (item.url) {
                    window.open(item.url);
                  }
                }}
              >
                <div className="title">{t(`know.t${index + 1}`)}</div>
                <Progress
                  percent={10}
                  showInfo={false}
                  strokeColor={twoColors}
                  size={["100%", 2]}
                />
                <div className="desc">{t(`know.d${index + 1}`)}</div>
              </div>
            ))}
            <div className="fill-logo logo">
              <img src={logo} width={120} className="logo" alt="" />
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};
