/** @format */
import "./index.scss";
import { Divider } from "antd";
import { useTranslation, Trans } from "react-i18next";
import { ServicesList } from "@/contants";
import LazyLoad from "react-lazyload";

export default () => {
  const { t } = useTranslation();

  return (
    <div className="fill-card service-card" data-aos="fade-up" id="services">
      <div
        className="card-title"
        style={{ textAlign: "center", marginBottom: 20 }}
      >
        <Trans i18nKey="services.title">
          Our
          <span className="show-color linear">Services</span>
        </Trans>
      </div>
      <div
        className="title-detail"
        style={{
          marginBottom: 80,
        }}
      >
        {t("services.desc")}
      </div>

      <div className="operate-content">
        {Object.keys(ServicesList).map((key: string, index: number) => (
          <div className="operate-card" key={key}>
            <div
              data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
              data-aos-delay={(index + 1) * 200}
            >
              <Trans i18nKey={`services.s${index + 1}`}>
                {ServicesList[key].title}
              </Trans>
              <div className="desc">
                <Divider className="divider" />
                {t(`services.d${index + 1}`)}
              </div>
            </div>
            <div
              data-aos={index % 2 === 0 ? "fade-left" : "fade-right"}
              data-aos-delay={(index + 1) * 200}
            >
              <LazyLoad>{ServicesList[key].icons}</LazyLoad>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
