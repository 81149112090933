/** @format */
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/home";
export default () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/"></Route>
      </Routes>
    </BrowserRouter>
  );
};
