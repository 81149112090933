/** @format */
import "./index.scss";
import { useTranslation, Trans } from "react-i18next";
import { holdersList } from "@/contants";

import AdvantageArrow from "@/assets/advantage_arrow.png";

export default () => {
  const { t } = useTranslation();

  return (
    <div className="fill-card">
      <div data-aos="fade-up">
        <div className="card-title card-title-holders">
          <Trans i18nKey="holders.title">
            Designed For{" "}
            <span className="show-color linear">Storage Providers</span>
            And Filecoin Holders
          </Trans>
        </div>
      </div>
      <ul className="holders-content">
        {holdersList.map((holderItem, index) => {
          return (
            <li key={holderItem.key} data-aos="fade-up">
              <div>
                <img className="holder-img" src={holderItem.img} alt="" />
              </div>
              <div className="holder-title">
                {t(`holders.advantage${index + 1}`)}
              </div>
              <div className="holder-txt">{t(`holders.desc${index + 1}`)}</div>
              <img className="arrow-img" src={AdvantageArrow} alt="" />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
