/** @format */

import advantage01 from "@/assets/advantage01.png";
import advantage02 from "@/assets/advantage02.png";
import advantage03 from "@/assets/advantage03.png";
import advantage04 from "@/assets/advantage04.png";
import operate01 from "@/assets/operate01.png";
import operate02 from "@/assets/operate02.png";
import operate03 from "@/assets/operate03.png";
import operate04 from "@/assets/operate04.png";

import Blog1 from "@/assets/blog1.webp";
import Blog2 from "@/assets/blog2.png";
import Blog3 from "@/assets/blog3.png";
import Blog4 from "@/assets/blog4.png";

import FilscanLogo from "@/assets/filscan.png";
import GreLogo from "@/assets/Gre.png";
import BFGLogo from "@/assets/BFG.png";
import FileDriveLogo from "@/assets/FileDrive.png";
import ForceCommunityLogo from "@/assets/Force.png";
import FilFoxLogo from "@/assets/FilFox.png";
import ProtocolLabs from "@/assets/ProtocolLabs.png";
import FileCoin from "@/assets/Filecoin.png";

import {
  TwitterOutlined,
  GithubOutlined,
  MediumOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const holdersList = [
  {
    key: "Decentralization",
    img: advantage01,
  },
  {
    key: "Liquidity",
    img: advantage02,
  },
  {
    key: "Storage Power",
    img: advantage03,
  },
  {
    key: "Passive Income",
    img: advantage04,
  },
];

export const ServicesList: Record<string, any> = {
  Stake: {
    key: "Stake",
    title: (
      <div>
        <div className="title">Stake</div>
        <div className="sub-title">PROVIDE FIL LIQUIDITY</div>
        <div className="sub-title">RECEIVE FIT TOKEN</div>
        <div className="sub-title">RE-STAKE TO EARN FIG GOVERNANCE TOKEN</div>
      </div>
    ),
    icons: <img src={operate01} />,
    text: "Value of FIT in terms of FIL constantly increases due to the continuous replenishment of loan interest into the pool, thereby expanding the total liquidity",
  },
  Redeem: {
    key: "Redeem",
    title: (
      <div>
        <div className="title">Redeem</div>
        <div className="sub-title">REDEEM FIT TOKEN</div>
        <div className="sub-title">WITHDRAW FIL LIQUIDITY</div>
      </div>
    ),
    icons: <img src={operate02} />,
    text: "Liquidity providers have the discretion to withdraw FIL at any time through the smart contract. The model-defined exchange rate ensures a fair distribution of loan interest yield",
  },
  Borrow: {
    key: "Borrow",
    title: (
      <div>
        <div className="title">Borrow</div>
        <div className="sub-title">PLEDGE MINER’S BENEFICIARY</div>
        <div className="sub-title">LOAN FOR STORAGE POWER GROWTH</div>
      </div>
    ),
    icons: <img src={operate03} />,
    text: "Filecoin Storage Providers are able to borrow up to 100% of their entire miner account balance, and the borrowing interest rate is solely determined by the pool utilization rate",
  },
  Repay: {
    key: "Repay",
    title: (
      <div>
        <div className="title">REPAY</div>
        <div className="sub-title">FLEXIBLE LOAN TERMS</div>
        <div className="sub-title">REPAY ANY AMOUNT AT ANYTIME</div>
        <div className="sub-title">REWARD FIG GOVERNANCE TOKEN</div>
      </div>
    ),
    icons: <img src={operate04} />,
    text: "Loan can be repaid in full or partially at any time, and both principal and interest will be replenished back into the pool as vailable liquidity",
  },
};

export const knowList = [
  {
    title: "Github",
    desc: "Our Smart Contracts, UI, Developer Tools, and more",
    url: "https://github.com/FILL-Lab",
  },
  {
    title: "White Paper",
    desc: "Details of protocol and model design",
    url: "https://docsend.com/view/6sj3kt5abdcygmmp",
  },
  {
    title: "Blog",
    desc: "Articles by the team members",
    url: "https://medium.com/@FILLiquid",
  },
  {
    title: "Testnet",
    desc: "Testing App on Filecoin Calibration Testnet",
    url: "https://test.filliquid.io/",
  },
  {
    title: "Faucet",
    desc: "Subscribe for TFIL test token",
    url: "https://faucet.calibnet.chainsafe-fil.io/funds.html",
  },
  {
    title: "Governance",
    desc: "Proposals and idea discussions",
    url: "https://fill-liquidity-pool-for-storage.gitbook.io/fill_doc/economics/governance-token-fig-filliquid-governance",
  },
];

export const blogList = [
  {
    title: "FiLLiquid Meme Contest — October 12th to 26th",
    link: "https://medium.com/@FILLiquid/filliquid-meme-contest-october-12th-to-26th-7f29bcdb65ed",
    imgUrl: Blog1,
    author: "FILLiquid",
    pubDate: "Oct 13,2023",
    guid: "FiLLiquid Meme Contest — October 12th to 26th",
  },
  {
    title: "FIL VEGAS 2023 and ESPA Recap",
    link: "https://medium.com/@FILLiquid/fil-vegas-2023-and-espa-recap-cd701426783a",
    imgUrl: Blog2,
    author: "FILLiquid",
    pubDate: "Oct 13,2023",
    guid: "FIL VEGAS 2023 and ESPA Recap",
  },
  {
    title: "What are Liquidity Pools in Crypto?",
    link: "https://medium.com/@FILLiquid/what-are-liquidity-pools-in-crypto-96bb3ffd1f8f",
    imgUrl: Blog3,
    author: "FILLiquid",
    pubDate: "Oct 11,2023",
    guid: "What are Liquidity Pools in Crypto?",
  },
  {
    title:
      "Introducing FILLiquid: The First Decentralized Lending Platform on Filecoin FVM",
    link: "https://medium.com/@FILLiquid/introducing-filliquid-the-first-decentralized-lending-platform-on-filecoin-fvm-8c5bd02449f5",
    imgUrl: Blog4,
    author: "FILLiquid",
    pubDate: "Oct 3,2023",
    guid: "Introducing FILLiquid: The First Decentralized Lending Platform on Filecoin FVM",
  },
];

export const partersList = [
  {
    name: "filcoin",
    logo: FileCoin,
    url: "https://filecoin.io/",
  },
  {
    name: "protocolLabs",
    logo: ProtocolLabs,
    url: "https://protocol.ai/",
  },
  {
    name: "gre",
    logo: GreLogo,
    url: "https://greaterheat.com/",
  },
  {
    name: "forceCommunity",
    logo: ForceCommunityLogo,
    url: "https://www.forcecommunity.io/",
  },
  {
    name: "bfg",
    logo: BFGLogo,
    url: "http://www.bfg.capital/",
  },
  {
    name: "filscan",
    logo: FilscanLogo,
    url: "https://filscan.io/",
  },
  {
    name: "filfox",
    logo: FilFoxLogo,
    url: "https://filfox.info/",
  },
  {
    name: "fileDrive",
    logo: FileDriveLogo,
    url: "https://filedrive.io/",
  },
];

export const linkList: Array<Record<string, any>> = [
  {
    title: "Email",
    link: "mailto:support@fliquid.io",
    icon: (
      <svg
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5180"
      >
        <path
          fill="white"
          d="M926.47619 355.644952V780.190476a73.142857 73.142857 0 0 1-73.142857 73.142857H170.666667a73.142857 73.142857 0 0 1-73.142857-73.142857V355.644952l304.103619 257.828572a170.666667 170.666667 0 0 0 220.745142 0L926.47619 355.644952zM853.333333 170.666667a74.044952 74.044952 0 0 1 26.087619 4.778666 72.704 72.704 0 0 1 30.622477 22.186667 73.508571 73.508571 0 0 1 10.678857 17.67619c3.169524 7.509333 5.12 15.652571 5.607619 24.210286L926.47619 243.809524v24.380952L559.469714 581.241905a73.142857 73.142857 0 0 1-91.306666 2.901333l-3.632762-2.925714L97.52381 268.190476v-24.380952a72.899048 72.899048 0 0 1 40.155428-65.292191A72.97219 72.97219 0 0 1 170.666667 170.666667h682.666666z"
          p-id="5181"
        ></path>
      </svg>
    ),
  },
  {
    title: "Telegram",
    link: [
      {
        label: "EN",
        url: "https://t.me/+3goIWl2G11NmZGNl",
      },
      {
        label: "CN",
        url: "https://t.me/FILLiquid_CN",
      },
      {
        label: "KR",
        url: "https://t.me/filliquidKR",
      },
    ],
    icon: (
      <svg
        className="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1750"
        width={20}
      >
        <path
          d="M417.28 795.733333l11.946667-180.48 327.68-295.253333c14.506667-13.226667-2.986667-19.626667-22.186667-8.106667L330.24 567.466667 155.306667 512c-37.546667-10.666667-37.973333-36.693333 8.533333-55.466667l681.386667-262.826666c31.146667-14.08 61.013333 7.68 49.066666 55.466666l-116.053333 546.56c-8.106667 38.826667-31.573333 48.213333-64 30.293334L537.6 695.466667l-84.906667 82.346666c-9.813333 9.813333-17.92 17.92-35.413333 17.92z"
          fill="white"
          p-id="1751"
        ></path>
      </svg>
    ),
  },
  {
    title: "Twitter",
    link: "https://x.com/FILLiquid",
    icon: <TwitterOutlined />,
  },
  {
    title: "Github",
    link: "https://github.com/FILL-Lab",
    icon: <GithubOutlined />,
  },
  {
    title: "Medium",
    link: "https://medium.com/@FILLiquid",
    icon: <MediumOutlined />,
  },
  {
    title: "Discord",
    link: "https://discord.com/invite/filliquid",
    icon: (
      <svg
        className="icon"
        viewBox="0 0 1280 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4018"
      >
        <path
          fill="white"
          d="M1049.062 139.672a3 3 0 0 0-1.528-1.4A970.13 970.13 0 0 0 808.162 64.06a3.632 3.632 0 0 0-3.846 1.82 674.922 674.922 0 0 0-29.8 61.2 895.696 895.696 0 0 0-268.852 0 619.082 619.082 0 0 0-30.27-61.2 3.78 3.78 0 0 0-3.848-1.82 967.378 967.378 0 0 0-239.376 74.214 3.424 3.424 0 0 0-1.576 1.352C78.136 367.302 36.372 589.38 56.86 808.708a4.032 4.032 0 0 0 1.53 2.75 975.332 975.332 0 0 0 293.65 148.378 3.8 3.8 0 0 0 4.126-1.352A696.4 696.4 0 0 0 416.24 860.8a3.72 3.72 0 0 0-2.038-5.176 642.346 642.346 0 0 1-91.736-43.706 3.77 3.77 0 0 1-0.37-6.252 502.094 502.094 0 0 0 18.218-14.274 3.638 3.638 0 0 1 3.8-0.512c192.458 87.834 400.82 87.834 591 0a3.624 3.624 0 0 1 3.848 0.466 469.066 469.066 0 0 0 18.264 14.32 3.768 3.768 0 0 1-0.324 6.252 602.814 602.814 0 0 1-91.78 43.66 3.75 3.75 0 0 0-2 5.222 782.11 782.11 0 0 0 60.028 97.63 3.728 3.728 0 0 0 4.126 1.4A972.096 972.096 0 0 0 1221.4 811.458a3.764 3.764 0 0 0 1.53-2.704c24.528-253.566-41.064-473.824-173.868-669.082zM444.982 675.16c-57.944 0-105.688-53.174-105.688-118.478s46.818-118.482 105.688-118.482c59.33 0 106.612 53.64 105.686 118.478 0 65.308-46.82 118.482-105.686 118.482z m390.76 0c-57.942 0-105.686-53.174-105.686-118.478s46.818-118.482 105.686-118.482c59.334 0 106.614 53.64 105.688 118.478 0 65.308-46.354 118.482-105.688 118.482z"
          p-id="4019"
        ></path>
      </svg>
    ),
  },
  {
    title: "Youtube",
    link: "https://www.youtube.com/@FILLIQUID",
    icon: <YoutubeOutlined />,
  },
];

export const COUNTDOWN_START_TIME = dayjs.tz(
  "2024-02-10 9:00:00",
  "Asia/Shanghai"
);
