/** @format */
import "./index.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import LightBox from "@/components/lightBox";

import PlayIcon from "@/assets/play_icon.png";
import VideoCover from "@/assets/video_cover.png";

export default () => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const { t } = useTranslation();

  const handleLightboxClose = () => {
    setIsLightboxOpen(false);
  };

  return (
    <div className="video-card" data-aos="fade-up">
      <LightBox isOpen={isLightboxOpen} onClose={handleLightboxClose} />
      <div className="video-container" onClick={() => setIsLightboxOpen(true)}>
        <img className="video-cover" src={VideoCover} />
        <img className="play" src={PlayIcon} />
      </div>
    </div>
  );
};
