/** @format */
import "./index.scss";
import { useEffect, useState } from "react";
import { Button, message } from "antd";
import { useTranslation } from "react-i18next";
import LocaleSwitcherSelect from "@/components/LocaleSwitcher";

import logo_white from "@/assets/fill_logo_white.png";

import { isTestnetStart, getLanguage } from "@/utils";

export default () => {
  const [isScrolling, setIsScrolling] = useState(false);

  const isProd = window?.location?.host.includes("io");

  const [messageApi, contextHolder] = message.useMessage();

  const { t, i18n } = useTranslation();

  const routers = [
    {
      name: t("home"),
      path: "/",
    },
    {
      name: t("service"),
      anchor: "services",
    },
    {
      name: t("blogs"),
      anchor: "blog",
    },
    {
      name: t("partners"),
      anchor: "partners",
    },
    {
      name: t("docs"),
      path: "https://fill-liquidity-pool-for-storage.gitbook.io/fill_doc/abstract/abstract",
    },
    {
      name: t("audits"),
      path: "https://fill-liquidity-pool-for-storage.gitbook.io/fill_doc/governance/audits",
    },
  ];

  const handleRouterChange = (router: any) => {
    if (router.path) {
      if (router?.path === "/") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        window.open(router.path);
      }
    }

    if (router.anchor) {
      const element = document.getElementById(router.anchor);
      element?.scrollIntoView({
        block: "start",
      });
    }
  };

  const handleDappLaunch = () => {
    // if (!isTestnetStart()) {
    //   return messageApi.info("Coming soon!");
    // }
    window.open(`https://dapp.filliquid.io/${getLanguage(i18n.language)}`);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const listener = () => {
        setIsScrolling(window.scrollY > 150);
      };
      window.addEventListener("scroll", listener);
      return () => {
        window.removeEventListener("scroll", listener);
      };
    }
  }, []);

  return (
    <>
      <div className={`fill-header ${isScrolling ? "isScrolling" : ""}`}>
        <div className="fill-logo">
          <img src={logo_white} className="logo" alt="" />
        </div>
        <div className="router-container">
          {routers.map((router) => (
            <div
              className="router-item"
              key={router.name}
              onClick={() => handleRouterChange(router)}
            >
              {router.name}
            </div>
          ))}
        </div>

        <div className="operate-container">
          <LocaleSwitcherSelect />
          <div className="linear-btn-wrapper">
            <Button
              className="linear-btn common-btn"
              onClick={handleDappLaunch}
            >
              {t("launch-dapp")}
            </Button>
          </div>
        </div>
      </div>
      {contextHolder}
    </>
  );
};
