import BigNumber from "bignumber.js";
import { walletState } from "./type";
import dayjs from "dayjs";
import { COUNTDOWN_START_TIME } from "@/contants";

export const setStr = (str: string) => {
  return `${String(str).slice(0, 6)}...${String(str).slice(-4)}`;
};

const bignumberFormat = (num: BigNumber) => {
  return num
    .toFormat(1, { groupSeparator: "", decimalSeparator: "." })
    .split(".")[0];
};

const bignumberToInt = (num: BigNumber) => {
  return Number(bignumberFormat(num));
};

export function getValueDivide(
  num: number,
  pow: number = 18,
  unit: number = 6
) {
  let res = new BigNumber(num || 0).dividedBy(Math.pow(10, pow));
  return res.toFixed(unit);
}

export function getValueMultiplied(num: number | string, pow: number = 18) {
  return new BigNumber(num).multipliedBy(Math.pow(10, pow)).toFixed(0);
}

export function getAccount(wallet: walletState) {
  if (wallet.result) {
    if (Array.isArray(wallet.result)) {
      return wallet.result[0];
    }
  }
}

export function getObligation(
  amount: number,
  rate: number,
  timer: number | BigNumber
) {
  const newTime = getTimes(timer);
  const tNum = BigNumber(rate * 100).multipliedBy(newTime);
  const numValue = BigNumber(amount)
    .multipliedBy(BigNumber(Math.exp(Number(tNum.toString()))))
    .dividedBy(Math.pow(10, 18));
  return numValue.toFixed(6);
}

export function getTimes(time: number | BigNumber) {
  return BigNumber(time).dividedBy(BigNumber(365 * 864000));
}

export function setRate(number: number) {
  return number * Math.pow(10, 6);
}

export function getRate(number: number | string) {
  return Number(number) / Math.pow(10, 6);
}

export function calcTerm(time: number | string) {
  // time 是几天 ,返回xs
  return BigNumber(time).multipliedBy(86400);
}

export const isTestnetStart = () => {
  return dayjs().isAfter(COUNTDOWN_START_TIME);
};

export const getLanguage = (lan: string) => {
  const locale = lan?.toLocaleLowerCase();
  if (locale.includes("en")) {
    return "en";
  }
  if (locale.includes("zh")) {
    return "zh";
  }
  if (locale.includes("ko")) {
    return "ko";
  }
  return "en";
};
